.minimizable-web-chat > button.maximize {
  background-color: #333;
  border-radius: 50%;
  border: 2px solid #333;
  bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  color: White;
  font-size: 30px;
  height: 64px;
  outline: 0;
  padding: 15px;
  position: fixed;
  right: 20px;
  width: 64px;
  padding-top: 10px;
}

.minimizable-web-chat > button.maximize > .red-dot {
  color: #EF0000;
  font-size: 14px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.minimizable-web-chat > button.maximize:focus:not(:active),
.minimizable-web-chat > button.maximize:hover:not(:active) {
  background-color: rgb(68, 68, 68);
  border-color: rgb(68, 68, 68);;
}

.minimizable-web-chat > button.maximize:active {
  background-color: White;
  color: #39C;
}

.minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  bottom: 20px;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 600px;
  width: 30%;
  transition: height 1s;
}

.minimizable-web-chat > .chat-box.hide {
  display: none;
}

.minimizable-web-chat > .chat-box.left {
  left: 20px;
}

.minimizable-web-chat > .chat-box.right {
  right: 20px;
}

.minimizable-web-chat > .chat-box > header {
  font-family: 'Gotham A', 'Gotham B', Arial, Helvetica, sans-serif;
  display: flex;
  background-color: #000;
font-style: normal;
font-weight: 400;

line-height: 26px;
letter-spacing: 0.4px;
color: #FFFFFF;
height: 66px;
}

.minimizable-web-chat > .chat-box > header > .chat-title {
margin-top: 20px;
margin-left: 21px;
}
.minimizable-web-chat > .chat-box > header > .filler {
  flex: 0 10000 100%;
}

.minimizable-web-chat > .chat-box > header > button {
  margin-right: 21px;
  background-color: Transparent;
  border: 0;
  color: #FFF;
  outline: 0;
  font-size: 20px;
  line-height: 26px;
}

.minimizable-web-chat > .chat-box > header > button:focus:not(:active),
.minimizable-web-chat > .chat-box > header > button:hover:not(:active) {
  color: rgba(255, 255, 255, .6);
}

.minimizable-web-chat > .chat-box > header > button:focus:active {
  color: rgba(255, 255, 255, .4);
}

.minimizable-web-chat > .chat-box > .react-web-chat {
  flex: 1;
}

.minimizable-web-chat > .chat-box  > .react-web-chat > div[role=form] > div[role=status]  {
padding-left: 16px;
padding-right: 16px;
}

div[role=group] .webchat__stacked-layout__attachment-row{
   width: unset !important;
}

.webchat__suggested-action .webchat__suggested-action__button:not(:disabled):not([aria-disabled="true"]){
  color: rgb(0, 0, 0) !important;
  border-color: rgb(0, 0, 0) !important;
}

/* input area */
.minimizable-web-chat > .chat-box  > .react-web-chat > div[role=form] > .webchat__send-box__main {
  background-color: #FAFAFA;
  border-top: none;
  height: 66px;
  padding-right: 21px;
  padding-left: 21px;
}

.minimizable-web-chat > .chat-box  > .react-web-chat > div[role=form] > .webchat__send-box__main > form > input {
  background-color: #FAFAFA;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #737373;
}

.minimizable-web-chat > .chat-box  > .react-web-chat > div[role=form] > .webchat__send-box__main > form > input::placeholder{
  color: #737373;
}

.minimizable-web-chat > .chat-box  > .react-web-chat > div[role=form] > .webchat__send-box__main >  button {
  width: 90px;
}

.disabledInput {
  background: rgba(133, 133, 133, 0.5);
  width: 100%;
  height: 100%;
  z-index: 20;
}

/* QnAMaker feedback */
.botActivityDecorator {
  min-height: 60px;
  position: relative;
}

.botActivityDecorator__button {
  cursor: pointer;
  width: 30px;
  z-index:999;
    position: relative;
}

button.botActivityDecorator__button:disabled {
  pointer-events:none;
  cursor:default;
  color:#999;
  opacity:.3
}

.botActivityDecorator .botActivityDecorator__buttonBar {
  list-style-type: none;
  margin: 0 0 0 10px;
  padding: 0;
  position: absolute;
}

.botActivityDecorator .botActivityDecorator__buttonBar .botActivityDecorator__button {
  background: White;
  border: solid 1px #e6e6e6;
  margin-bottom: 2px;
  padding: 2px 5px 5px;
}

/* Activity Status */
.activityStatus {
  font-family: 'Gotham A', 'Gotham B', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 8px;
  margin-top: 10px;
}

.activityStatus__sendStatus,
.activityStatus__timestampPretext {
  color: #000000;
}

.activityStatus__timestampContent {
  color: #A9A9A9;
}

/* Contact form */
.contactFormDecorator input  {
  font-family: GothamBook;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 1.63;
letter-spacing: 0.35px;
color: #000000;
margin-bottom: 12px;
  padding-bottom: 3px;
  background-color: #FAFAFA;
  border: 0;
  border-bottom: 1px solid #E0E0E0;
}

.contactFormDecorator .ac-pushButton {
  color: #000;
  border: 2px solid #000;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.contactFormDecorator .ac-pushButton.style-default.primary.style-positive{
  color: #FFF !important;
  background-color: #000;
}

.contactFormDecorator .ac-input-validation-failed  {
  border:0;
  border-bottom: 1px solid red;
}

/* assure colour of herocard buttons is black */
.ac-pushButton.style-default{
  color: #000 !important;
}

@media only screen and (min-width: 769px) {
  .minimizable-web-chat > .chat-box {
  max-width: 800px;
  min-width: 600px;
  }
  .minimizable-web-chat > .chat-box > header {
  font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .minimizable-web-chat > .chat-box {
    max-width: 600px;
    width: 90%;
  }
  .minimizable-web-chat > .chat-box > header {
  font-size: 18px;
  }
}